/* Main Section Styling */
.contact-us {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

/* Container Styling */
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 90%;
  max-width: 1250px;
}

/* Left Column Styling */
.left-column {
  flex: 1;
  min-width: 300px;
}

.section-title {
  font-size: 28px;
  font-weight: 600;
  color: #222;
  margin-bottom: 15px;
}

.left-column h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.left-column p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
}

.left-column b {
  font-size: 16px;
}

.left-column a {
  color: #000;
  text-decoration: none;
}

.left-column a:hover {
  text-decoration: underline;
}

/* Right Column Styling */
.right-column {
  flex: 1;
  min-width: 300px;
}

/* Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #222;
  margin-bottom: 8px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 14px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #ca7d24;
  box-shadow: 0 0 3px rgba(209, 141, 17, 0.5);
}

/* Button Styling */
.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.profile-image {
  width: 180px; /* Adjust as needed */
  height: auto;
  border-radius: 5%; /* Makes the image circular */
  margin-bottom: 15px; /* Adds spacing below the image */
}
