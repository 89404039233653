/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.logo .dot {
  color: #f50057;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.nav-links .contact {
  background-color: #ff5722;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
}

/* Logout and Login Buttons */
.btn-logout,
.btn-login {
  background-color: #f50057;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn-logout:hover,
.btn-login:hover {
  background-color: #d4004d;
}

/* Menu Toggle for Mobile */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  background-color: #333;
  height: 3px;
  width: 25px;
  margin: 3px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    text-align: center;
    margin: 1rem 0;
  }

  .nav-links .contact {
    margin: 1rem auto;
  }

  .menu-toggle {
    display: flex;
  }
}

/* Ensure content below navbar is pushed down when menu opens */
.navbar + div {
  padding-top: 60px;
}

.tag-line{
  font-size: 10px;
}