/* Container for the entire terms and conditions page */
.terms-container-1 {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Title styles */
.terms-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* General section styles */
.terms-section-1 {
  margin-bottom: 40px; /* Ensures spacing between sections */
}

/* Subtitle styles for sections */
.terms-subtitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #444;
  border-bottom: 2px solid #ddd; /* Visual separation */
  padding-bottom: 10px;
}

/* Subsection title styles */
.terms-subsection-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #555;
}

/* Paragraph styles */
.terms-section-1 p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: justify;
}

/* Grievance Officer contact styles */
.terms-contact {
  font-weight: bold;
  color: #222;
}

/* Specific adjustments for spacing and alignment */
.terms-section-1:last-of-type {
  margin-bottom: 0; /* Remove margin for the last section */
}
