/* Footer Container */
.footer-container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  max-width: 1200px;
}

/* Footer Box */
.footer-box {
  text-align: center;
  width: 100%;
}

/* Footer Brand */
.footer-brand {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.footer-dot {
  color: #ff5733; /* Highlight dot color */
}

/* Footer Description */
.footer-description {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

/* Footer Contact */
.footer-contact {
  font-size: 16px;
  color: #333;
}

.footer-email {
  color: #000;
  text-decoration: none;
}

.footer-email:hover {
  text-decoration: underline;
}
.footer-links {
  display: flex;
  justify-content: center;  /* Centers the links horizontally */
  gap: 15px;  /* Adds space between the links */
  align-items: center;  /* Vertically align the links */
  margin-bottom: 5px;  /* Adds some space below the links */
}

.footer-links a {
  text-decoration: none;
  color: #000; /* Adjust the text color */
  font-weight: normal; /* Adjust font weight if necessary */
}