/* Dashboard Container */
.dashboard-container {
    display: flex;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent content overflow */
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  
  /* Sidebar */
  .sidebar {
    width: 250px;
    background-color: #2c3e50; /* Dark sidebar color */
    color: #ecf0f1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%; /* Full height */
  }
  
  .sidebar .logo {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ecf0f1;
  }
  
  .sidebar .logo span {
    color: #e67e22;
  }
  
  .sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-nav li {
    margin: 15px 0;
  }
  
  .sidebar-nav a {
    text-decoration: none;
    color: #ecf0f1;
    padding: 10px 15px;
    display: block;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .sidebar-nav a.active,
  .sidebar-nav a:hover {
    background-color: #e67e22;
  }
  
  /* Main Section */
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    overflow-y: auto; /* Prevent scrolling issues */
  }
  
  /* Header */
  .header {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .header h1 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin: 0;
  }
  
  .profile {
    display: flex;
    align-items: center;
  }
  
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #e67e22;
  }
  
  .profile-name {
    font-size: 1rem;
    color: #2c3e50;
  }
  
  /* Content Area */
  .content {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
  }
  
  .card h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #2c3e50;
  }
  
  .card p {
    font-size: 1.5rem;
    color: #e67e22;
    font-weight: bold;
  }
  
  .chart {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .chart h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sidebar {
      width: 200px;
    }
  
    .cards {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 576px) {
    .header h1 {
      font-size: 1.2rem;
    }
  
    .profile-name {
      font-size: 0.9rem;
    }
  
    .cards {
      grid-template-columns: 1fr;
    }
  }
  